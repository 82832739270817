import { Tooltip, Modal } from 'bootstrap';

((ele) => {
	if (!ele && ele.length < 1) return;

	function changeTooltip(btn, text) {
		const tooltip = Tooltip.getInstance(btn);
		tooltip.setContent({ '.tooltip-inner': text });
	}

	function createConfirmationPopup() {
		const confirmationPopupLayout = () => {
			return `
				<div class="modal fade" id="confirmationPopup" tabindex="-1" aria-labelledby="confirmationPopupLabel" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered">
						<div class="modal-content">
							<button type="button" class="bg-primary btn-close position-absolute top-0 end-0 mt-n5" data-bs-dismiss="modal" aria-label="Fermer"></button>
							<div class="modal-body">
								<h3>Êtes-vous sûr de vouloir supprimer ?</h3>
								<div class="d-flex justify-content-end gap-5">
									<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
									<button type="button" class="btn btn-primary js-delete">Supprimer</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			`;
		};

		document.body.insertAdjacentHTML('beforeend', confirmationPopupLayout());

		const $confirmationPopup = document.getElementById('confirmationPopup');
		const confirmationPopup = new Modal($confirmationPopup);
	}

	function confirmation(btn) {
		const result = confirm("Êtes-vous sûr(e) de vouloir supprimer ce bien de vos favoris ?");

		if (result) {
			manageFavourites(btn);
			window.location.reload();
		}
	}

	function manageFavourites(btn) {
		const propertyId = btn.dataset.propertyId;
		let data = new FormData();

		data.append("property_id", propertyId);

		fetch(window.BOOKMARK_URL, {
			method: 'POST',
			body: data
		}).then(res => res.json())
		.then(res => {
			if (res.success) {
				const buttons = document.querySelectorAll(`button[data-property-id="${propertyId}"]`);

				if (res.is_bookmarked) {
					changeTooltip(btn, 'Ce bien est dans vos favoris');

					[].forEach.call(buttons, button => {
						button.classList.add('icon-favorite-added');
					});
				}

				if (!res.is_bookmarked) {
					changeTooltip(btn, 'Ajouter aux Favoris');

					[].forEach.call(buttons, button => {
						button.classList.remove('icon-favorite-added');
					});
				}

			}
		});
	}

	[].forEach.call(ele, btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();

			if (btn.classList.contains('js-confirmation')) {
				confirmation(btn);
			} else {
				manageFavourites(btn);
			}
		});
	});

})(document.querySelectorAll('.js-favourites'));
