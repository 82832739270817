import './validation.scss';

(function () {
	const forms = document.querySelectorAll('.needs-validation')

	Array.prototype.slice.call(forms)
		.forEach(function (form) {
			form.addEventListener('submit', function (event) {
				const inputs = form.querySelectorAll('[type="password"]');

				if (inputs && inputs.length > 1 && inputs[0].value !== '') {
					const errorMessage = 'Les mots de passe ne correspondent pas';

					inputs[0].setCustomValidity(inputs[1].value != inputs[0].value ? errorMessage : "");
					inputs[0].nextElementSibling.innerHTML = errorMessage;
				}

				if (!form.checkValidity()) {
					event.preventDefault();
					event.stopPropagation();
				}

				form.classList.add('was-validated');
			}, false);
		})
})();
