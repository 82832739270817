import './form-range.scss';

(function () {
	const rangeInputs = document.querySelectorAll('.form-range');

	function setPos(el) {
		const $output = document.querySelector(`output[for='${el.id}']`);
		const min = el.min;
		const max = el.max;
		const val = el.value;
		let pos = (val - min) * 100 / (max - min);
		let options;

		el.style.cssText += `--range-bg-pos: ${pos}% 100%`;
		$output.style.left = `calc(${pos}% + (${8 - pos * 0.15}px))`;

		if ($output.dataset.type == 'currency') {
			options = {
				style: "currency",
				currency: "EUR",
				maximumFractionDigits: 0,
				roundingIncrement: 5,
			}
		}

		if ( $output.dataset.type == 'unit') {
			options = {
				style: "unit",
  				unit: "year",
				unitDisplay: "long"
			}
		}

		const nf = new Intl.NumberFormat("fr-FR", options);
		$output.value = nf.format(val);
	}

	function handleInputChange(e) {
		let el = e.target;
		setPos(el);
	}

	Array.prototype.slice.call(rangeInputs)
		.forEach(function (input) {
			setPos(input);
			input.addEventListener('input', handleInputChange);
		});
})();
