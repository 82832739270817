import './features-list.scss';
import './property-img.scss';
import './property-description.scss';
import './property-slider.scss';
import './contact-form.scss';
import './loan-simulator';
import './create-account';
import './contact-form';

((ele, btn, className) => {
	if (!ele) return;

	btn.addEventListener('click', (event) => {
		event.preventDefault();
		ele.classList.remove(className);
		btn.classList.add('d-none');
	});


})(document.querySelector('.js-show-more'), document.querySelector('.js-btn-show-more'), 'property-description-truncate');

((ele) => {
	if (!ele) return;

	[].forEach.call(ele, btn => {
		btn.addEventListener('click', () => {
			history.back();
		});
	});

})(document.querySelectorAll('.js-go-back'));
