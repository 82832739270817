import './media-gallery.scss';
import {tns} from 'tiny-slider/src/tiny-slider.js';

(ele => {
	if (!ele) return;

	const slider = tns({
		container: ele,
		mode: 'carousel',
		controlsContainer: '#customize-controls',
		navContainer: '#customize-thumbnails',
		navAsThumbnails: true,
		items: 1,
		autoplay: false
	});

	const myModalEl = document.getElementById('popupMediaGallery');
	myModalEl.addEventListener('show.bs.modal', event => {
		slider.goTo(event.relatedTarget.dataset.index);
	});

})(document.querySelector('.js-media-gallery'));
