const compareBtn = (length, url) => {
	if (length < 2) return '';

	return `<a role="button" class="btn btn-primary" href="${url}">Comparer</a>`;
}

const comparisonPanelLayout = (items, isOpen, url) => {
	return `
	<div class="position-sticky bottom-0 text-white comparison-panel js-comparison-panel">
		<div class="bg-dark text-center">
			<button type="button" class="btn btn-link text-white text-decoration-none" aria-label="Toggle" data-bs-toggle="collapse" data-bs-target="#comparisonPanelBody" aria-expanded="${isOpen}" aria-controls="comparisonPanelBody">Comparer les annonces (${items.length}/4) <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.314 1.657 6.657 7.314 1 1.657" stroke="#fff" stroke-width="2"/></svg></button>
		</div>
		<div class="bg-secondary py-3 collapse ${isOpen && 'show'}" id="comparisonPanelBody">
			<div class="container">
				<div class="d-flex flex-column flex-md-row gap-5">
					<div class="row row-cols-2 row-cols-lg-4 g-5 flex-grow-1">
						${items.map((item, index) => `
							<div class="col">
								<div class="comparison-panel-overlay">
									<img src="${item.imageUrl}" alt="">
									<button type="button" class="bg-primary btn-close position-absolute top-0 end-0 js-delete-property" data-index="${index}" aria-label="Remove property"></button>
								</div>
							</div>
						`).join('\n')}
						${Array.apply(null, Array(4 - items.length)).map(() => `
							<div class="col">
								<div class="h-100 w-100 bg-dark"></div>
							</div>
						`).join('\n')}
					</div>
					<div class="d-flex flex-column-reverse gap-3">
						<button type="button" class="btn btn-outline-light js-delete-everything" aria-label="Tout supprimer">Tout supprimer</button>
						${compareBtn(items.length, url)}
					</div>
				</div>
			</div>
		</div>
	</div>
	`;
};

export default comparisonPanelLayout;
