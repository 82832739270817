(function (ele) {
	if (!ele || ele.length < 1) return;

	[].forEach.call(ele, btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();

			const result = confirm(`Êtes-vous sûr(e) de vouloir supprimer cette recherche de vos recherches sauvegardées ?`);

			if (result) {
				const card = btn.closest('.card');
				const searchId = card.dataset.searchId;
				let data = new FormData();

				data.append('search_id', searchId);

				fetch(window.DELETE_USER_SEARCH_URL, {
					method: 'POST',
					body: data
				})
				.then((response) => response.json())
				.then((result) => {
					if (result.success) {
						window.location.reload();
					}
				});
			}
		});
	});
})(document.querySelectorAll('.js-remove-search'));
