import { Modal } from 'bootstrap';

(function (ele, className) {

	if (!ele || ele.length < 1) return;

	[].forEach.call(ele, form => {
		form.addEventListener('submit', async (e) => {
			e.preventDefault();

			const resultCont = form.querySelector(className);
			if (resultCont) {
				resultCont.innerHTML = "";
			}

			function showMessage(formName, form) {
				const formCont = form.querySelector('.modal-form');
				let message;

				switch(formName) {
					case 'whitePaper':
						message = `Merci. Nous vous envoyons, sur l'adresse email que vous nous avez indiquée, le lien de téléchargement de la brochure.`;
						break;
					case 'studentResidences':
						message = `Merci. Nous vous envoyons, sur l'adresse email que vous nous avez indiquée, le lien de téléchargement.`;
					case 'estimateProperty':
						message = `Votre demande d’estimation a bien été envoyée. Nous reviendrons vers vous dans les plus brefs délais.`;
						break;
					case 'forgotPassword':
						message = `Une demande de réinitialisation de mot de passe a été envoyée sur votre adresse email.`;
						break;
					case 'callBack':
						message = `Merci pour votre message. Il a bien été envoyé. Nous vous contacterons dans les plus brefs délais.`;
						break;
					default:
						message = `Merci pour votre message. Il a été envoyé.`;
						break;
				}

				if (formCont) {
					formCont.insertAdjacentHTML('afterend', `<p>${message}</p>`);
					formCont.classList.add('d-none');
				} else {
					form.insertAdjacentHTML('beforeend', `<p>${message}</p>`);
					// clear fields
				}
			}

			if (form.checkValidity()) {
				const formAction = e.target.action;

				form.querySelector('.js-btn-submit').disabled = true;

				let response = await fetch(formAction, {
					method: e.target.method,
					body: new FormData(form)
				}).catch(function(error) {
					//throw new Error(error);
				}).finally(() => {
					let $gRecaptcha = form.querySelector('.g-recaptcha');
					if ($gRecaptcha && grecaptcha) {
						grecaptcha.execute($gRecaptcha.dataset.sitekey, {action: "form"}).then((token) => {
							$gRecaptcha.value = token;
						});
					}
				});

				let result = await response.json();

				if (result.success) {
					form.querySelector('.js-btn-submit').disabled = false;

					switch(form.dataset.action) {
						case 'reload':
							location.reload();
							break;
						case 'save_search_reload':
							const searchBtn = document.querySelector('.js-save-search');
							const formSearchResults = searchBtn.closest('form');

							fetch(window.SAVE_USER_SEARCH_URL, {
								method: 'POST',
								body: new FormData(formSearchResults),
							})
							.then((responseSave) => responseSave.json())
							.then((resultSave) => {
								if (resultSave.success) {
									localStorage.setItem('saveSearchResults', resultSave.search_id);
									location.reload();
								}
							});
							break;
						case 'redirect':
							let params = (new URL(formAction)).searchParams;
							location.replace(params.get('next'));
							break;
						case 'save_search_redirect':
							const searchBtn2 = document.querySelector('.js-save-search');
							const formSearchResults2 = searchBtn2.closest('form');

							fetch(window.SAVE_USER_SEARCH_URL, {
								method: 'POST',
								body: new FormData(formSearchResults2),
							})
							.then((response) => response.json())
							.then((result) => {
								if (result.success) {
									let searchParams = (new URL(formAction)).searchParams;
									location.replace(searchParams.get('next'));
								}
							});
							break;
						case 'create_account':
							document.querySelector('.js-create-account').click();
							break;
						case 'message':
							showMessage(form.dataset.name, form);
							break;
						default:
							break;
					}

				} else {
					form.querySelector('.js-btn-submit').disabled = false;

					if (resultCont && result.errors) {
						[].forEach.call(result.errors, error => {
							resultCont.insertAdjacentHTML('beforeend', `<p class="invalid-feedback d-block">${error}</p>`);
						});
					}
				}
			}

		});
	});

})(document.querySelectorAll('.js-submit'), '.js-submit-result');
