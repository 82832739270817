(function (form) {
	if (!form) return;

	form.addEventListener('submit', (e) => {
		e.preventDefault();

		const result = confirm("Souhaitez-vous vraiment supprimer votre compte ?");

		if (result) {
			form.submit();
		}
	});

})(document.querySelector('.js-remove-account'));
