import {tns} from 'tiny-slider/src/tiny-slider.js';
import './slider.scss';

(ele => {
	if (ele) {
		[].forEach.call(ele, gallery => {
			const $controlsContainer = gallery.dataset.controls ? gallery.dataset.controls : false;
			const slider = tns({
				container: gallery,
				mode: 'carousel',
				nav: false,
				controlsPosition: 'bottom',
				controlsContainer: $controlsContainer,
				items: 1,
				slideBy: 'page',
				autoplay: true,
				autoplayHoverPause: true,
				autoplayButtonOutput: false,
				responsive: {
					768: {
						items: 2
					},
					1200: {
						items: 3
					}
				}
			});
		});
	}
})(document.querySelectorAll('.js-slider'));
