import './top-navbar.scss';
import { Collapse } from 'bootstrap';
import './calendly';

((ele, className) => {
	if (!ele) return;

	const collapsibles = ele.getElementsByClassName('collapse');
	let isOpen = false;
	let collapsibleEl;

	//Change navigation background colour
	let changeBg = function(pos) {
		if (pos > 0 || isOpen) {
			ele.classList.add(className);
		} else {
			ele.classList.remove(className);
		}
	};

	[].forEach.call(collapsibles, collapsible => {
		collapsible.addEventListener('show.bs.collapse', function () {
			isOpen = true;
			const myobj = Collapse.getInstance(collapsible);
			collapsibleEl = myobj[Object.keys(myobj)[0]].id;

			changeBg(window.pageYOffset);
		});

		collapsible.addEventListener('hidden.bs.collapse', function () {
			const myobj = Collapse.getInstance(collapsible);

			if (myobj[Object.keys(myobj)[0]].id == collapsibleEl) {
				isOpen = false;
				changeBg(window.pageYOffset);
			}
		});
	});

	window.addEventListener('scroll', () => {
		changeBg(window.pageYOffset);
	});

	window.onload = function() {
		changeBg(window.pageYOffset);
	};

})(document.querySelector('.js-nav-bg-change'), 'bg-dark');
