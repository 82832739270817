((elements => {
	elements.forEach(authOpenerElem => {
		// @NOTE authOpenerElem should be the `a` emelent.
		if (!authOpenerElem.href) {
			return;
		}
		authOpenerElem.addEventListener("click", (event) => {
			event.preventDefault();
			window.open(authOpenerElem.href, null, {popup: true});
		})
	});
}))(document.querySelectorAll(".auth-opener"))
