(function (btn) {
	if (!btn) return;

	function checkSelected(selects) {
		const newSelects = Array.from(selects);
		const isSelected = newSelects.filter((item) => item.value !== '').length;

		if (isSelected) {
			btn.classList.remove('d-none');
		} else {
			if (!btn.dataset.searchId) {
				btn.classList.add('d-none');
			}
		}
	}

	const form = btn.closest('form');
	const selects = form.querySelectorAll('select');

	checkSelected(selects);

	[].forEach.call(selects, select => {
		select.addEventListener('change', () => {
			checkSelected(form.querySelectorAll('select'));
		});
	});

})(document.querySelector('.js-show-alert-btn'));

(function (btn) {
	if (!btn) return;

	function setFormDataAction(ele, action) {
		ele.querySelector('form').dataset.action = action;
	}

	btn.addEventListener('click', (e) => {
		e.preventDefault();

		setFormDataAction(document.querySelector('#login'), 'save_search_reload');
		setFormDataAction(document.querySelector('#createAccount'), 'save_search_redirect');
	});
})(document.querySelector('.js-save-search'));

(function (btn) {
	if (!btn) return;

	function changeBtn(searchId) {
		btn.innerHTML = `Supprimer cette recherche de vos recherches enregistrées`;
		btn.dataset.searchId = searchId;
	}

	let params = (new URL(window.location)).searchParams;
	const searchId = params.get('search-id');

	if (localStorage.saveSearchResults) {
		changeBtn(localStorage.saveSearchResults);
		localStorage.removeItem('saveSearchResults');
	}

	if (searchId) {
		changeBtn(searchId);
	}

	btn.addEventListener('click', (e) => {
		e.preventDefault();

		const searchId = btn.dataset.searchId;
		const form = btn.closest('form');

		if (searchId) {
			let data = new FormData();
			data.append('search_id', searchId);

			fetch(window.DELETE_USER_SEARCH_URL, {
				method: 'POST',
				body: data
			})
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					btn.innerHTML = `Créer une alerte mail pour cette recherche`;
					delete btn.dataset.searchId;
				}
			});
		} else {
			fetch(window.SAVE_USER_SEARCH_URL, {
				method: 'POST',
				body: new FormData(form),
			})
			.then((response) => response.json())
			.then((result) => {
				if (result.success) {
					changeBtn(result.search_id);
				}
			});
		}
	});

})(document.querySelector('.js-manage-search'));
