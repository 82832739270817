import './calendly.scss';

((ele) => {
	if (!ele) return;

	const link = `${ele.getAttribute('href')}?hide_event_type_details=1&hide_gdpr_banner=1&text_color=003262&primary_color=FF8B2C`;

	ele.addEventListener('click', (e) => {
		e.preventDefault();

		Calendly.initPopupWidget({url: link});
	});
})(document.querySelector('.js-calendly'));
