import './comparison-panel.scss';
import comparisonPanelLayout from './comparison-panel-layout';
import { Collapse, Tooltip } from 'bootstrap';

function setItemWithExpiry(key, value, ttl = 2592000000) {
	const now = new Date();
	// By default ttl (Time To Live) is 2592000000 (30 days)

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl
	};

	localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiry(key) {
	const itemStr = localStorage.getItem(key);

	// If the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();

	// Compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry || !item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
}

((ele) => {
	if (!ele || ele.length < 1) return;

	let $properties = [];
	let $comparisonPanel;

	function initComparisonPanel(isOpen, toggleOpen) {
		if (!getItemWithExpiry('properties')) return;

		$properties = JSON.parse(getItemWithExpiry('properties'));
		let $comparisonPanelFooter = document.querySelector('.js-comparison-panel-footer');
		const comparePageUrl = `${window.COMPARE_PAGE_URL}?${$properties.map(item => `id=${item.id}`).join('&')}`;

		document.querySelector('main').insertAdjacentHTML('beforeend', comparisonPanelLayout($properties, isOpen, comparePageUrl));
		$comparisonPanel = document.querySelector('.js-comparison-panel');

		if (toggleOpen) {
			const bsCollapse = Collapse.getOrCreateInstance(document.getElementById('comparisonPanelBody'), {
				toggle: toggleOpen
				});
		}

		if (!$comparisonPanelFooter) {
			document.querySelector('main').insertAdjacentHTML('afterend', '<div class="bg-secondary pb-8 js-comparison-panel-footer"></div>');
			$comparisonPanelFooter = document.querySelector('.js-comparison-panel-footer');
		}

		const $delAll = $comparisonPanel.querySelector('.js-delete-everything');

		$delAll.addEventListener('click', () => {
			localStorage.removeItem('properties');
			$comparisonPanel.parentNode.removeChild($comparisonPanel);
			$comparisonPanelFooter.parentNode.removeChild($comparisonPanelFooter);
			$properties.map((item) => {
				const $cards = document.querySelectorAll(`.card[data-property-id="${item.id}"]`);

				if (!!$cards && $cards.length > 0) {
					[].forEach.call($cards, $card => {
						$card.querySelector('.js-compare').disabled = false;
					});
				}
			});
			$properties = [];
			$comparisonPanel = null;
		});

		const $delBtns = $comparisonPanel.querySelectorAll('.js-delete-property');

		[].forEach.call($delBtns, btn => {
			btn.addEventListener('click', () => {
				const propertyId = $properties.filter((item, index) => index === parseInt(btn.dataset.index))[0].id;
				const $cards = document.querySelectorAll(`.card[data-property-id="${propertyId}"]`);

				if (!!$cards && $cards.length > 0) {
					[].forEach.call($cards, $card => {
						$card.querySelector('.js-compare').disabled = false;
					});
				}

				if ($delBtns.length > 1) {
					$properties = $properties.filter((item, index) => index !== parseInt(btn.dataset.index));
					setItemWithExpiry('properties', JSON.stringify($properties));
					$comparisonPanel.parentNode.removeChild($comparisonPanel);

					initComparisonPanel(true);
				} else {
					localStorage.removeItem('properties');
					$comparisonPanel.parentNode.removeChild($comparisonPanel);
					$comparisonPanelFooter.parentNode.removeChild($comparisonPanelFooter);
					$properties = [];
					$comparisonPanel = null;
				}
			});
		});
	}

	[].forEach.call(ele, link => {
		link.addEventListener('click', () => {
			const $tooltip = Tooltip.getInstance(link);

			if (!!$tooltip) {
				$tooltip.hide();
			}

			if (!!localStorage.properties) {
				$properties = JSON.parse(getItemWithExpiry('properties'));
			}

			if ($properties.length < 4) {
				let isOpen = false;
				const imageUrl = link.closest('.card').querySelector('img').getAttribute('src').replace('width=700&height=450', 'width=400&height=210');
				const propertyId = link.closest('.card').dataset.propertyId;
				const $cards = document.querySelectorAll(`.card[data-property-id="${propertyId}"]`);

				[].forEach.call($cards, $card => {
					$card.querySelector('.js-compare').disabled = true;
				});

				$properties = [...$properties, {imageUrl: imageUrl, id: propertyId}];

				setItemWithExpiry('properties', JSON.stringify($properties));

				if (!!$comparisonPanel) {
					isOpen = $comparisonPanel.querySelector('.collapse').classList.contains('show');
					$comparisonPanel.parentNode.removeChild($comparisonPanel);
				}

				if (isOpen) {
					initComparisonPanel(true);
				} else {
					initComparisonPanel(false, true);
				}

			} else {

				if (!document.querySelector('.js-comparison-alert')) {
					document.querySelector('.js-comparison-panel').insertAdjacentHTML('beforeend', `<div class="js-comparison-alert bg-primary">
						<div class="container py-3 text-white">
							Impossible d'ajouter un 5ème produit. Vous pouvez comparer jusqu'à 4 produits maximum. Merci de retirer l'un des produits de votre sélection.
						</div>
					</div>`);
				}

				const $collapseEle = document.getElementById('comparisonPanelBody');

				if (!$collapseEle.classList.contains('show')) {
					const bsCollapse = Collapse.getOrCreateInstance($collapseEle, {
						show: true
					});
				}
			}
		});
	});

	initComparisonPanel(false);

	if ($properties.length > 0) {
		$properties.map((item) => {
			const $properties = document.querySelectorAll(`.card[data-property-id="${item.id}"]`);

			if (!!$properties) {
				[].forEach.call($properties, $property => {
					const compareBtn = $property.querySelector('.js-compare');

					if (compareBtn) {
						compareBtn.disabled = true;
					}
				});
			}
		});
	}

})(document.querySelectorAll('.js-compare'));
