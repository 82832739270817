((ele) => {
	if (!ele) return;

	const $form = document.forms["simulatorLMNP"];
	const $rangeInputs = $form.querySelectorAll('.form-range');

	function handleInputChange() {
		const f1 = $form.fieldname1.value;
		const f2 = $form.fieldname2.value;
		const f3 = $form.fieldname3.value;
		const f4 = $form.fieldname4.value;
		const f5 = $form.fieldname5.value;
		const f6 = $form.fieldname6.value;
		const f7 = $form.fieldname7.value;
		const f8 = Number($form.fieldname8.value);


		$form.fieldname10.value = Math.round(f3*0.5);
		$form.fieldname11.value = Math.round(f1/2);
		$form.fieldname12.value = Math.round(f2/10);

		$form.fieldname14.value = Math.round(f3*0.5);
		$form.fieldname15.value = Math.round(f3-(f2/10)-(f1/20)-(f4)-f7-f5-f6);

		$form.fieldname17.value = Math.round(f3*0.5*(f8+15.5/100));

		if ((f3-(f1/20)-(f2/10)-f4-(f7/12)-f5-f6)*(f8+15.5/100) > 0) {
			$form.fieldname18.value = (f3-(f1/20)-(f2/10)-f4-f7-f5-f6)*(f8+15.5/100);
		}

		if ((f3-(f1/20)-(f2/10)-f4-f7-f5-f6)*(f8+15.5/100) <= 0) {
			$form.fieldname18.value = "Pas d’impôts à payer";
		}
	}

	$form.fieldname8.addEventListener('change', handleInputChange);

	Array.prototype.slice.call($rangeInputs)
		.forEach(function (input) {
			input.addEventListener('input', handleInputChange);
		});

	handleInputChange();

})(document.querySelector('.js-lmnp-simulator'));
