((checkbox) => {
	if (!checkbox) return;

	checkbox.addEventListener('change', () => {
		const form = checkbox.closest('form');

		if (checkbox.checked) {
			const accountForm = document.querySelector('.js-create-account-form');

			accountForm.last_name.value = form.last_name.value;
			accountForm.first_name.value = form.first_name.value;
			accountForm.phone_number.value = form.phone_number.value;
			accountForm.email.value = form.email.value;

			form.dataset.action = 'create_account';
		} else {
			form.dataset.action = 'message';
		}
	});

})(document.querySelector('.js-checkbox-create-account'));
