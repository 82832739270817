import './btn-back-top.scss';

((ele, focusEle) => {
	if (!ele) return;

	ele.addEventListener('click', (e) => {
		e.preventDefault();

		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		focusEle.focus({
			preventScroll: false
		});
	});

})(document.querySelector('.js-back-top'), document.querySelector('.navbar-brand'));
