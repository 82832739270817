import { Modal } from 'bootstrap';

function setBlockHeight(contactFormWrapper, tnsControlsSecondary) {
	if (!contactFormWrapper) return;

	// form top frame y-pos on screen + form height - top padding + inner block padding (2rem)
	const block1Top = contactFormWrapper.offsetTop + 710 - 80 + 32;
	const block2Top = tnsControlsSecondary ? tnsControlsSecondary.offsetTop : 0;
	const distance = Math.abs(block1Top - block2Top);

	contactFormWrapper.style.height = distance + 'px';
}

((contactFormWrapper, tnsControlsSecondary) => {
	setBlockHeight(contactFormWrapper, tnsControlsSecondary);
	window.addEventListener('resize', () => setBlockHeight(contactFormWrapper, tnsControlsSecondary));
})(document.querySelector('.contact-form-wrapper'), document.querySelector('.tns-controls-secondary'));

(function (form) {
	if (!form) return;

	if (window.CMS) {
		window.CMS.$(document).on('cms-ready', () => {
			if (window.CMS.settings.toolbar == "expanded") {
				form.parentElement.style.top = '150px';
			}
		});
	}

	form.querySelector('.js-btn-submit').disabled = true;

	if(form.privacy_check) {
		form.privacy_check.addEventListener('click', e => {
			const checkBox = e.currentTarget;

			form.querySelector('.js-btn-submit').disabled = !checkBox.checked;
		});
	}

	form.addEventListener('submit', async (e) => {
		try{
			e.preventDefault();

			if(!form.privacy_check.checked) return;

			form.querySelector('.js-btn-submit').disabled = true;
			form.querySelector('.js-result').innerHTML = '';

			const response = await fetch(e.target.action, {
				method: e.target.method,
				body: new FormData(form),
			})
			const result = await response.json();

			form.querySelector('.js-btn-submit').disabled = false;

			if (result.success) {
				const modal = new Modal(document.querySelector("#contactThanks"));
				modal.show();
			} else {
				form.querySelector('.js-result').innerHTML = `<p class="text-primary">Quelque chose s'est mal passé</p>`;
			}

			form.classList.remove('was-validated');
			form.querySelector('.js-btn-submit').disabled = true;
			form.reset();
		} catch(error) {
			form.querySelector('.js-result').innerHTML = `<p class="text-primary">${error.message}</p>`;
			form.querySelector('.js-btn-submit').disabled = true;
		}
	});

})(document.querySelector('#contact-form'));
