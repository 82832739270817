(function (ele) {

	if (!ele || ele.length < 1) return;

	[].forEach.call(ele, $form => {
		$form.addEventListener('submit', (e) => {
			e.preventDefault();

			if ($form.checkValidity()) {
				$form.querySelector('.js-btn-submit').disabled = true;

				fetch(e.target.action, {
					method: e.target.method,
					body: new FormData($form),
				})
				.then((response) => response.json())
				.then((result) => {
					$form.querySelector('.js-btn-submit').disabled = false;

					if (result.success) {
						$form.querySelector('.js-result').innerHTML = `<p class="text-primary">Votre inscription est bien prise en compte. Merci !</p>`;
						// $form.email.value = '';
					} else {
						$form.querySelector('.js-result').innerHTML = `<p class="text-primary">Adresse email n'est pas valide ou existe déjà</p>`;
					}
				})
				.finally(() => {
					$gRecaptcha = $form.querySelector('.g-recaptcha');
					if ($gRecaptcha && grecaptcha) {
						grecaptcha.execute($gRecaptcha.dataset.sitekey, {action: "form"}).then((token) => {
							$gRecaptcha.value = token;
						});
					}
				});
			}

		});
	});

})(document.querySelectorAll('.js-newsletter'));
