import './share-group.scss';

((ele) => {
	if (!ele && ele.length == 0) return;

	[].forEach.call(ele, btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			const link = btn.getAttribute('href');

			btn.blur();

			navigator.clipboard.writeText(link);

			document.querySelector('body').insertAdjacentHTML('beforeend', `<div class="js-confirmation fixed-top bg-primary text-white"><div class="container py-3 py-xl-5 text-center">Le lien a bien été copié</div></div>`);

			setTimeout(() => {
				const $confirmation = document.querySelector('.js-confirmation');

				$confirmation.parentNode.removeChild($confirmation);
			}, "2000")
		});
	});

})(document.querySelectorAll('.js-sharelink'));

((groups) => {
	if (!groups && groups.length < 1) return;

	[].forEach.call(groups, group => {
		const btn = group.querySelector('.share-group-btn');
		const panel = group.querySelector('.share-group-panel');

		btn.addEventListener('click', (e) => {
			e.preventDefault();

			panel.classList.toggle('d-flex');
		});

		window.addEventListener('click', (e) => {
			if (!btn.contains(e.target)) {
				panel.classList.remove('d-flex');
			}
		});
	});

})(document.querySelectorAll('.share-group'));
