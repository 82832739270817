import { Tooltip } from 'bootstrap';
import './loan-simulator.scss';

((ele) => {
	if (!ele) return;

	const $form = document.forms["loanSimulator"];
	let $deposit;
	let $K; // mortgage amount
	let $I; // interest rate
	let $n; // duration
	let $graphLoan;
	let $graphRent;

	function monthlyMA(K, I, n) {
		// direct calculation of monthly payments
		// A = k* I / (1 – (1 + I)–n)

		// A - periodic amount to be paid
		// K - mortgage amount
		// I - interest rate

		return (K * I) / (1 - Math.pow(1 + I, -n));
	}

	function stringToNumber(s){
		return Number(String(s).replace(/[^0-9.-]+/g,""))
	}

	const $propertyPrice = stringToNumber($form.propertyPrice.value);
	const $notaryFees = stringToNumber($form.notaryFees.value) / 100;
	const $totalPrice = $propertyPrice + $propertyPrice * $notaryFees; // property price + notary fees (8 to 12% of property price)
	const $monthlyCharges = stringToNumber($form.monthlyCharges.value);
	const $monthlyRent = stringToNumber($form.rent.value);
	let $result = $form.querySelector('.js-loan-simulator-result');
	let $legend = $form.querySelector('.js-loan-simulator-legend');
	const $interest = $form.querySelector('.js-loan-simulator-interest');
	const interestTooltip = Tooltip.getInstance($interest);

	function loanSimulator() {
		$deposit = stringToNumber($form.deposit.value);
		$K = $totalPrice - $deposit;
		$I = stringToNumber($form.mortgageRate.value) / 1200; // monthly rate
		$n = stringToNumber($form.loanTerm.value) * 12; // duration in months (10/15/20/25 ans)

		const $monthlyMortgageAmount = Math.floor(monthlyMA($K, $I, $n));
		const $monthlyCost = $monthlyMortgageAmount + $monthlyCharges;
		const $costOrProfit = Math.floor($monthlyRent - $monthlyCost);

		interestTooltip.setContent({ '.tooltip-inner': `${$monthlyMortgageAmount} €` });

		if ($costOrProfit > 0) {
			$result.innerHTML = `Bénéfice <span class="loan-sumulator-result-currency">${$costOrProfit} &euro;</span> mensuel`;
			$legend.innerHTML = `Bénéfice mensuel`;
			$graphRent = 180;
			$graphLoan = 360 - Math.floor($monthlyCost / ($monthlyRent + $monthlyCost + $costOrProfit) * 360);

		} else if ($costOrProfit < 0 ) {
			$result.innerHTML = `Effort d'épargne <span class="loan-sumulator-result-currency">${Math.abs($costOrProfit)} &euro;</span> mensuel`;
			$legend.innerHTML = `Effort d'épargne mensuel`;
			$graphLoan = 180;
			$graphRent = Math.floor($monthlyRent / ($monthlyRent + $monthlyCost + Math.abs($costOrProfit)) * 360);
		}

		$result.style.cssText += `--loan: ${$graphLoan}deg; --rent: ${$graphRent}deg`;
	}

	function inputMasks() {
		const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0,
		roundingIncrement: 5 });

		const $items = $form.querySelectorAll('[data-type="currency"]');

		[].forEach.call($items, item => {
			item.value = formatter.format(stringToNumber(item.value));
		});

		$form.deposit.addEventListener('focusin', (e) => {
			let value = e.target.value;
			e.target.value = stringToNumber(value);
		});

		$form.deposit.addEventListener('focusout', (e) => {
			let value = e.target.value;
			e.target.value = formatter.format(stringToNumber(value));
		});
	}

	loanSimulator();
	inputMasks();

	$form.loanTerm.addEventListener("input", loanSimulator);

	$form.addEventListener('focusout', loanSimulator);

	$form.onsubmit = function(e) {
		loanSimulator();
		e.preventDefault();
	}

})(document.querySelector('.js-loan-simulator'));
