const useGtag = (func) => (...args) => {
	if (window.dataLayer?.push) {
		const gtag = (...gtagArgs) => window.dataLayer.push(gtagArgs);
		return func(gtag, ...args);
	}
	const gtagProxy = (...gtagArgs) => {
		console.info("noGtag", ...gtagArgs);
	};
	return func(gtagProxy, ...args);
};

useGtag((gtag, elements) => {
	elements.forEach((ele) => {
		ele.addEventListener("click", () => {
			gtag("event", "click_modal_open", {
				modal: "sign_in",
			});
		});
	});
})(document.querySelectorAll("[data-bs-target='#login']"));

useGtag((gtag, elements) => {
	elements.forEach((ele) => {
		ele.addEventListener("click", () => {
			gtag("event", "click_modal_open", {
				modal: "sign_up",
			});
		});
	});
})(document.querySelectorAll("[data-bs-target='#createAccount']"));

useGtag((gtag, elements) => {
	elements.forEach((ele) => {
		ele.addEventListener("click", (event) => {
			gtag("event", "click_auth_button", {
				provider: event.target.getAttribute("data-auth-track"),
			});
		});
	});
})(document.querySelectorAll("[data-auth-track]"));
