import './property-stretched-link.scss';
import './manage-search';
import './remove-search';

((ele, input) => {
	if (!ele && !input) return;

	[].forEach.call(ele, btn => {
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			input.value = btn.dataset.value;
			input.closest('form').submit();
		});
	});

})(document.querySelectorAll('.js-sort'), document.querySelector('.js-sort-value'));

(($results, searchString) => {
	if (!$results || searchString === '') return;

	const mainOffset = document.querySelector('main')?.offsetTop || 0;
	const headerHeight = document.querySelector('#navParent')?.offsetHeight || 0;

	window.scrollTo({
		top: $results.offsetTop + mainOffset - headerHeight,
		behavior: 'smooth'
	});

	$results.focus({
		preventScroll: false
	});
})(document.querySelector('.js-search-result'), window.location.search);
