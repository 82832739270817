import './hero.scss';
import { Modal } from 'bootstrap';
import Player from '@vimeo/player';

// Popup markup
const createVideoPopup = () => {
	return `
		<div class="modal fade" id="heroPopup" tabindex="-1" aria-labelledby="heroPopupLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered">
				<div class="modal-content">
					<button type="button" class="bg-primary btn-close position-absolute top-0 end-0 mt-n5" data-bs-dismiss="modal" aria-label="Fermer"></button>
					<div class="modal-body p-0">
						<div class="ratio ratio-16x9" id="heroPopupFrame"></div>
					</div>
				</div>
			</div>
		</div>
	`;
};

((ele) => {
	if (!ele) return;

	const $link = ele.getAttribute('href');

	document.body.insertAdjacentHTML('beforeend', createVideoPopup());

	const $videoPopup = document.getElementById('heroPopup');
	const videoPopup = new Modal($videoPopup);

	const url = new URL($link);
	const $videoFrame = document.getElementById('heroPopupFrame');
	const player = new Player($videoFrame, {
		id: url.pathname.substring(1)
	});

	$videoPopup.addEventListener('shown.bs.modal', function (event) {
		player.play();
	});

	$videoPopup.addEventListener('hide.bs.modal', function (event) {
		player.pause();
	});

})(document.querySelector('.js-open-popup'));
