import "ol/ol.css";
import "./geographical-search.scss";

import Feature from "ol/Feature";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import OSM from "ol/source/OSM";
import Point from "ol/geom/Point";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Icon, Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Popover } from "bootstrap";
import { fromLonLat } from "ol/proj";

function getProperties(element) {
	const dataPropertiesString = element.getAttribute("data-properties");
	return dataPropertiesString.length > 1
		? JSON.parse(decodeURI(dataPropertiesString))
		: [];
}

function createPin(propery) {
	const iconFeature = new Feature({
		geometry: new Point(fromLonLat(propery.point)),
		name: propery.title,
		...propery,
	});

	const iconStyle = new Style({
		image: new Icon({
			anchor: [0.5, 60],
			anchorXUnits: "fraction",
			anchorYUnits: "pixels",
			src: propery.iconImg,
		}),
	});

	iconFeature.setStyle(iconStyle);

	return iconFeature;
}

((mapElement) => {
	if (!mapElement) return;

	const properties = getProperties(mapElement);
	let vectorLayer = null;
	const rasterLayer = new TileLayer({ source: new OSM() });

	if (properties.length > 0) {
		const vectorSource = new VectorSource({
			features: properties.map((propery) => createPin(propery)),
		});

		vectorLayer = new VectorLayer({ source: vectorSource });
	}

	const map = new Map({
		layers: [rasterLayer, vectorLayer],
		target: mapElement,
		view: new View({
			center: fromLonLat([2.2069522, 48.85877]),
			zoom: 5,
		}),
	});

	if (vectorLayer !== null) {
		var layerExtent = vectorLayer.getSource().getExtent();

		if (layerExtent) {
			map.getView().fit(layerExtent, { padding: [100, 50, 50, 50] });
		}
	}

	const element = document.getElementById("popup");
	const popup = new Overlay({ element: element, stopEvent: false });

	map.addOverlay(popup);

	map.on("click", function (evt) {
		const feature = map.forEachFeatureAtPixel(
			evt.pixel,
			function (feature) {
				return feature;
			}
		);

		const popover = Popover.getInstance(element);
		if (popover !== null) {
			popover.dispose();
		}

		if (feature) {
			popup.setPosition(fromLonLat(feature.get("point")));

			const popover = new Popover(element, {
				html: true,
				content: `<div class="map-card d-flex justify-content-start align-items-stretch">
					<img src="${feature.get("image")}" class="map-img" alt="${feature.get("name")}">
					<div class="map-body">
						<a class="map-title pb-0" href="${feature.get("url")}">${feature.get(
					"name"
				)}</a>
						<ul class="map-text mt-0">
							<li>Prix : ${feature.get("price")}€</li>
							<li>Rentabilité : ${feature.get("profitability")} %</li>
							<li>Gestionnaire : ${feature.get("gestionnaire")}</li>
						</ul>
					</div>
				</div>`,
				title: "",
			});

			popover.show();
		}
	});

	map.on("pointermove", function (e) {
		const pixel = map.getEventPixel(e.originalEvent);
		const hit = map.hasFeatureAtPixel(pixel);
		map.getTarget().style.cursor = hit ? "pointer" : "";
	});

	map.on("movestart", function () {
		const popover = Popover.getInstance(element);

		if (popover !== null) {
			popover.dispose();
		}
	});

	const propetyCards = document.querySelectorAll(
		".js-geographical-search-property"
	);
	let activeId = null;

	propetyCards &&
		propetyCards.forEach((card) => {
			const id = card.getAttribute("data-property-id");
			const property = properties.find((p) => p.id == id);

			card.addEventListener("mouseover", () => {
				console.log(activeId, property.id);

				if (activeId !== property.id) {
					map.getView().animate(
						{
							center: fromLonLat(property.point),
							duration: 1000,
						},
						() => {
							const popover = Popover.getInstance(element);
							if (popover !== null) {
								popover.dispose();
							}

							if (property) {
								const popover = new Popover(element, {
									html: true,
									content: `<div class="map-card d-flex justify-content-start align-items-stretch">
								<img src="${property.image}" class="map-img" alt="${property.title}">
								<div class="map-body">
									<a class="map-title pb-0" href="${property.url}">${property.title}</a>
									<ul class="map-text mt-0">
										<li>Prix : ${property.price}€</li>
										<li>Rentabilité : ${property.profitability} %</li>
										<li>Gestionnaire : ${property.gestionnaire}</li>
									</ul>
								</div>
							</div>`,
									title: "",
								});

								popup.setPosition(fromLonLat(property.point));
								popover.show();
							}
						}
					);
				}

				activeId = property.id;
			});

			card.addEventListener("mouseout", () => {
				const popover = Popover.getInstance(element);
				activeId = null;

				if (popover !== null) {
					popover.dispose();
				}
			});
		});
})(document.querySelector(".js-geographical-search"));
