import './comparison-table.scss';
import './comparison-panel';


((ele) => {
	if (!ele) return;

	const $rows = ele.querySelector('tbody').querySelectorAll('tr');

	[].forEach.call($rows, row => {
		const title = row.querySelector('th').innerHTML;
		const $cells = row.querySelectorAll('td');

		[].forEach.call($cells, cell => {
			cell.insertAdjacentHTML('afterBegin', `<div class="text-secondary">${title}</div>`);
		});
	});

})(document.querySelector('.js-comparison'));
