import "ol/ol.css";
import "./map.scss";

import Feature from "ol/Feature";
import Map from "ol/Map";
import OSM from "ol/source/OSM";
import Overlay from "ol/Overlay";
import Point from "ol/geom/Point";
import TileJSON from "ol/source/TileJSON";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Icon, Style } from "ol/style";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { fromLonLat } from "ol/proj";

((mapElement) => {
	if (!mapElement) return;
	const pointString = mapElement.getAttribute("data-map-point");
	let vectorLayer;
	const rasterLayer = new TileLayer({ source: new OSM() });
	const layers = [rasterLayer];
	let point = fromLonLat([2.2069522, 48.85877]);

	if(pointString && pointString.length) {
		const iconImg = mapElement.getAttribute("data-map-icon");
		const [lot, lat] = pointString.split(",");

		point = fromLonLat([parseFloat(lot), parseFloat(lat)]);
		const iconFeature = new Feature({
			geometry: new Point(point),
		});
		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 46],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				src: iconImg,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const vectorSource = new VectorSource({
			features: [iconFeature],
		});

		vectorLayer = new VectorLayer({
			source: vectorSource,
		});

		layers.push(vectorLayer);
	}

	const map = new Map({
		layers,
		target: mapElement,
		view: new View({
			center: point,
			zoom: 12,
		}),
	});
})(document.querySelector(".js-map"));
